import { getMonthName, getDayOfWeekDesc } from '@/use/DateFormatter'
import { ordinal } from '@/use/NumberFormatter'

const dayInterval1List = ['1', '2', '3', '4', '5', '6', '10', '20']
const weekInterval1List = ['1', '2', '3', '4', '5', '6', '8', '10', '20']
const monthIntervalF2List = ['1', '2', '3', '4', '5', '6', '12', '18']
const monthIntervalT3List = ['1', '2', '3', '4', '5', '6', '12', '18']

const daysOfMonthList = [...Array(30)].map(
  (key, value) =>
    new Object({ value: (value + 1).toString(), text: ordinal(value + 1) })
)

const weeksOfMonthList = [
  { value: '1', text: 'first' },
  { value: '2', text: 'second' },
  { value: '3', text: 'third' },
  { value: '4', text: 'fourth' },
  { value: '0', text: 'last' }
]

const daysOfWeekList = [...Array(7)].map(
  (key, value) =>
    new Object({ value: value.toString(), text: getDayOfWeekDesc(value) })
)

const monthList = [...Array(12)].map(
  (key, value) =>
    new Object({ value: (value + 1).toString(), text: getMonthName(value + 1) })
)

const selectLists = {
  DayInterval1: dayInterval1List,
  WeekInterval1: weekInterval1List,
  MonthIntervalF2: monthIntervalF2List,
  MonthIntervalT3: monthIntervalT3List,
  DaysOfMonth: daysOfMonthList,
  WeeksOfMonth: weeksOfMonthList,
  DaysOfWeek: daysOfWeekList,
  Months: monthList
}

export { selectLists }
