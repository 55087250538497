<template>
  <div v-if="cardData">
    <PageTitle
      featureName="billing"
      functionName="Details"
      :target="cardData.Name"
      :pageName="$options.name"
    />
    <div class="d-flex flex-wrap">
      <v-card class="ma-2 indigo lighten-5" width="400">
        <v-card-title class="d-flex justify-space-between">
          <div>{{ cardData.Name }}</div>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon class="d-flex" @click="navigateToCustomer">
                <v-icon
                  :color="feature.customers.color"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icon.customer }}
                </v-icon>
              </v-btn>
            </template>
            <span>View Customer</span>
          </v-tooltip>
        </v-card-title>
        <v-card-subtitle :class="statusClass">
          <div>
            <span>{{ cardData.StatusDescription }}</span>
            <span class="float-right black--text mr-2">
              {{ cardData.Code }}
            </span>
          </div>
        </v-card-subtitle>
        <v-card-text>
          <div v-if="cardData.AlertType">
            <span>Alert: </span>
            <span class="red--text font-weight-bold">
              {{ cardData.AlertType }} / {{ cardData.AlertText }}
            </span>
          </div>
          <BillingSummaryCard
            :cardData="cardData"
            :hideSummaryLink="true"
            @update="billingSummaryCardUpdateEvent"
          />
        </v-card-text>
      </v-card>
      <div>
        <v-card class="ma-2 indigo lighten-5" width="400">
          <v-card-text>
            <OnlinePaymentCard
              :cardData="cardData"
              @update="onlinePaymentCardUpdateEvent"
            />
          </v-card-text>
        </v-card>
        <v-card class="ma-2" width="400" height="80">
          <v-card-text>
            <v-card-actions>
              <v-btn
                small
                color="primary"
                class="ml-2"
                @click="statementReportButtonClick"
              >
                <v-icon class="mr-2">{{ icon.report }}</v-icon>
                Statement
              </v-btn>
              <v-btn
                small
                color="primary"
                class="ml-2"
                @click="transactionDetailReportButtonClick"
              >
                <v-icon class="mr-2">{{ icon.report }}</v-icon>
                Transaction Report
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <v-card class="mx-2" max-width="1000">
      <v-card-title>
        <div class="text-subtitle-1 blue--text">
          Transactions
        </div></v-card-title
      >
      <v-data-table
        dense
        :items-per-page="10"
        :footer-props="{
          'items-per-page-options': items_per_page,
          'show-first-last-page': true
        }"
        :headers="dataTable.HeaderList"
        :items="dataTable.LineItemList"
        :options.sync="options"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :server-items-length="dataTable.TotalRecords"
        :loading="loading"
        class="elevation-1"
        ><template v-slot:item.BusinessDate="{ item }"
          ><span>{{ new Date(item.BusinessDate).toLocaleDateString() }}</span>
        </template>
        <template v-slot:item.DebitAmount="{ item }"
          ><span>{{ toMoney(item.DebitAmount) }}</span>
        </template>
        <template v-slot:item.CreditAmount="{ item }"
          ><span>{{ toMoney(item.CreditAmount) }}</span>
        </template>
        <template v-slot:item.RunningAmount="{ item }"
          ><span>{{ toMoney(item.RunningAmount) }}</span>
        </template>
        <template v-slot:item.Actions="{ item }">
          <v-icon
            v-if="!item.IsOnlinePayment"
            small
            class="mr-2"
            @click="editItemClick(item)"
          >
            {{ icon.lineItemEdit }}
          </v-icon>
          <v-icon
            v-if="!item.IsOnlinePayment"
            small
            @click="deleteItemClick(item)"
          >
            {{ icon.lineItemDelete }}
          </v-icon>
          <v-icon v-else small @click="onlinePaymentItemClick(item)">
            {{ feature.onlinePayments.icon }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="showTransactionDialog" max-width="400" persistent>
      <Transaction
        :customerId="cardData.CustomerId"
        :transactionId="selectedTransactionId"
        :isDialogOpen="showTransactionDialog"
        @close="transactionCloseEvent"
        @update="transactionUpdateEvent"
      />
    </v-dialog>

    <v-dialog v-model="showInvoiceDialog" width="1000" persistent>
      <Invoice
        :isDialogOpen="showInvoiceDialog"
        :customerId="cardData.CustomerId"
        :invoiceId="selectedTransactionId"
        @close="invoiceClose"
        @update="invoiceUpdate"
      />
    </v-dialog>

    <v-dialog v-model="showOnlinePaymentEditDialog" width="400">
      <OnlinePaymentModify
        :transactionId="selectedTransactionId"
        :isDialogOpen="showOnlinePaymentEditDialog"
        @close="hideOnlinePaymentEditDialog(false)"
        @update="hideOnlinePaymentEditDialog(true)"
      />
    </v-dialog>

    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'
import { useRouter } from 'vue2-helpers/vue-router'
import { host } from '@/services/HostAPI'
import { icon, feature } from '@/use/Constants'
import BillingSummaryCard from '@/components/Billing/BillingSummaryCard'
import OnlinePaymentCard from '@/components/OnlinePayment/OnlinePaymentCard'
import Transaction from '@/components/Billing/Transaction'
import Invoice from '@/components/Billing/Invoice'
import OnlinePaymentModify from '@/components/OnlinePayment/OnlinePaymentModify'
import reportInfo from '@/constants/reports'
import ReportService from '@/services/ReportService'
import { toMoney } from '@/use/NumberFormatter'

export default {
  name: 'BillingDetails',
  components: {
    BillingSummaryCard,
    OnlinePaymentCard,
    OnlinePaymentModify,
    Transaction,
    Invoice
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const dialogUtil = ref()

    const router = useRouter()
    const showTransactionDialog = ref(false)
    const showInvoiceDialog = ref(false)
    const showOnlinePaymentEditDialog = ref(false)
    const cardData = ref(null)
    const selectedTransactionId = ref(null)
    const loading = ref(true)
    const options = ref({})
    const sortBy = ref([])
    const sortDesc = ref([])
    const dataTable = ref({})
    const items_per_page = [10, 15, 20, 50]
    const firstTime = ref(true)

    watch(
      () => options.value,
      () => {
        loadDataTable().catch(e => alert('error: ' + e.message))
      },
      { deep: true }
    )

    const statusClass = computed(() => {
      return (
        cardData.value && feature.customers.statusColors[cardData.value.Status]
      )
    })

    const load = async () => {
      const rq = { Id: props.id, RetrieveSites: false }
      const rs = await host.customer.card(rq)
      cardData.value = rs.Info
    }

    const loadDataTable = async () => {
      loading.value = true
      const { sortBy, sortDesc, page, itemsPerPage } = options.value
      const rq = {
        CustomerId: props.id,
        Pagination: {
          Page: page,
          RowsPerPage: itemsPerPage,
          SortBy: sortBy.length > 0 ? sortBy[0] : 'Date',
          IsDescending: sortDesc.length > 0 ? sortDesc[0] : false
        }
      }
      const rs = await host.billing.loadDetailsDataTable(rq)
      if (firstTime.value) {
        firstTime.value = false
        dataTable.value.TotalRecords = rs.TotalRecords
        if (options.value.page != rs.TotalPages) {
          options.value.page = rs.TotalPages
        } else {
          dataTable.value = rs
        }
      } else {
        dataTable.value = rs
      }
      loading.value = false
    }

    const refresh = () => {
      load()
      loadDataTable()
    }

    const statementReportButtonClick = () => {
      ReportService.emitShowReportDialog({
        ReportId: reportInfo.report.statement.reportId,
        ParameterList: [
          { Name: 'EmailOnly', Value: 'False' },
          { Name: 'StartAccountCode', Value: cardData.value.Code },
          { Name: 'IncludeZeroBalances', Value: 'True' },
          { Name: 'IncludeCreditBalances', Value: 'True' },
          { Name: 'OneOnly', Value: 'True' },
          { Name: 'EmailOnly', Value: cardData.value.PrintNoStatement }
        ],
        ParameterHideList: [
          'EmailOnly',
          'EmailSubject',
          'EmailBody',
          'BillingGroupId',
          'StartAccountCode',
          'OneOnly',
          'OrderBy',
          'IncludeZeroBalances',
          'IncludeCreditBalances'
        ],
        EmailInfo: {
          SendEmail: false,
          CustomerId: cardData.value.CustomerId,
          CustomerName: cardData.value.Name,
          Recipient: cardData.value.Email
        }
      })
    }

    const transactionDetailReportButtonClick = () => {
      ReportService.emitShowReportDialog({
        ReportId: reportInfo.report.transactionDetail.reportId,
        ParameterList: [
          { Name: 'AccountCode', Value: cardData.value.Code },
          { Name: 'BeginningDateTime', Value: '1980-01-01' }
        ],
        ParameterHideList: ['AccountCode'],
        EmailInfo: {
          SendEmail: false,
          CustomerId: cardData.value.CustomerId,
          CustomerName: cardData.value.Name,
          Recipient: cardData.value.Email
        }
      })
    }

    const editItemClick = item => {
      selectedTransactionId.value = item.TransactionId
      if (item.IsInvoice) {
        showInvoiceDialog.value = true
      } else {
        showTransactionDialog.value = true
      }
    }

    const deleteTransactionClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete ${item.TranCodeDesc} for ${toMoney(
            item.CreditAmount || item.DebitAmount
          )}`
        })
        .then(() => performDeleteTransaction(item))
        .catch(() => {
          return
        })
    }

    const deleteInvoiceClick = item => {
      dialogUtil.value
        .confirm({
          title: 'Please confirm',
          text: `Delete Invoice ${item.InvoiceNumber}`
        })
        .then(() => performDeleteInvoice(item))
        .catch(() => {
          return
        })
    }

    const performDeleteTransaction = async item => {
      const rq = {
        InfoId: item.TransactionId
      }
      const rs = await host.transaction.delete(rq)
      if (rs.IsSuccess) {
        refresh()
      }
    }

    const performDeleteInvoice = async item => {
      const rq = {
        InfoId: item.TransactionId
      }
      const rs = await host.invoice.delete(rq)
      if (rs.IsSuccess) {
        refresh()
      } else {
        dialogUtil.value.error(rs.Message)
      }
    }

    const deleteItemClick = item => {
      if (item.IsInvoice) {
        deleteInvoiceClick(item)
      } else {
        deleteTransactionClick(item)
      }
    }

    const onlinePaymentItemClick = item => {
      selectedTransactionId.value = item.TransactionId
      showOnlinePaymentEditDialog.value = true
    }

    const transactionCloseEvent = () => {
      showTransactionDialog.value = false
    }

    const transactionUpdateEvent = () => {
      showTransactionDialog.value = false
      refresh()
    }

    const invoiceUpdate = () => {
      showInvoiceDialog.value = false
      refresh()
    }

    const invoiceClose = () => {
      showInvoiceDialog.value = false
    }

    const hideOnlinePaymentEditDialog = reload => {
      showOnlinePaymentEditDialog.value = false
      if (reload) {
        refresh()
      }
    }

    const navigateToCustomer = () => {
      router.push({
        name: feature.customers.routerNameSummary,
        params: { id: cardData.value.CustomerId }
      })
    }

    const onlinePaymentCardUpdateEvent = () => {
      refresh()
    }

    const billingSummaryCardUpdateEvent = () => {
      refresh()
    }

    load()

    return {
      dialogUtil,
      showTransactionDialog,
      showInvoiceDialog,
      showOnlinePaymentEditDialog,
      cardData,
      statusClass,
      selectedTransactionId,
      loading,
      options,
      sortBy,
      sortDesc,
      dataTable,
      items_per_page,
      statementReportButtonClick,
      transactionDetailReportButtonClick,
      editItemClick,
      deleteItemClick,
      onlinePaymentItemClick,
      transactionCloseEvent,
      transactionUpdateEvent,
      invoiceUpdate,
      invoiceClose,
      hideOnlinePaymentEditDialog,
      navigateToCustomer,
      onlinePaymentCardUpdateEvent,
      billingSummaryCardUpdateEvent,
      toMoney,
      feature,
      icon
    }
  }
}
</script>

<style lang="scss" scoped></style>
