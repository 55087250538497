<template>
  <div>
    <v-data-table
      v-if="syncSessionList"
      dense
      :items-per-page="10"
      :footer-props="{
        'show-first-last-page': true
      }"
      :headers="syncSessionHeaders"
      :items="syncSessionList"
      class="mt-3 elevation-1"
      @click:row="selectSyncSessionRow"
    >
      <template v-slot:item.WhenStarted="{ item }">
        {{ toLocaleDateTimeString(item.WhenStarted) }}
      </template>
      <template v-slot:item.WhenCompleted="{ item }">
        {{ toLocaleDateTimeString(item.WhenCompleted) }}
      </template>
      <template v-slot:item.FilterFrom="{ item }">
        {{ toLocaleDateTimeString(item.FilterFrom) }}
      </template>
      <template v-slot:item.FilterTo="{ item }">
        {{ toLocaleDateTimeString(item.FilterTo) }}
      </template>
      <template v-slot:item.Current="{ item }">
        {{ item.CurrentStep }} / {{ item.CurrentIteration }}
      </template>
      <template v-slot:footer>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              absolute
              class="mt-3 mr-2"
              @click="refreshClick"
            >
              <v-icon>{{ icon.refresh }}</v-icon>
            </v-btn>
          </template>
          <span>Refresh list</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { icon } from '@/use/Constants'
import { toLocaleDateTimeString } from '@/use/DateFormatter'
export default {
  name: 'SyncSessionList',
  components: {},
  props: {
    infoId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const syncSessionList = ref([])
    const selectedSyncSessionRow = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      syncSessionList.value = await getSyncSessionList(props.infoId)

      if (
        syncSessionList.value.length === 0 ||
        selectedSyncSessionRow.value === null ||
        syncSessionList.value.filter(
          e => e.SyncSessionId === selectedSyncSessionRow.value.SyncSessionId
        ).length === 0
      ) {
        context.emit('refresh')
      }
    }

    const getSyncSessionList = async syncWithId => {
      const rq = { Criteria: { ParentInfoId: syncWithId } }
      const rs = await host.syncSession.list(rq)
      return rs.InfoList
    }

    const selectSyncSessionRow = event => {
      selectedSyncSessionRow.value = event
      context.emit('select', event)
    }

    const refreshClick = async () => {
      syncSessionList.value = await getSyncSessionList(props.infoId)
    }

    const syncSessionHeaders = [
      { value: 'WhenStarted', text: 'Started' },
      { value: 'WhenCompleted', text: 'Completed' },
      { value: 'Ticket', text: 'Ticket' },
      { value: 'StepCount', text: 'Steps', align: 'right' },
      { value: 'Current', text: 'Current' },
      { value: 'FilterFrom', text: 'Filter From' },
      { value: 'FilterTo', text: 'Filter To' },
      { value: 'ErrorMessage', text: 'Status Message' }
    ]

    initDialog()

    return {
      icon,
      syncSessionHeaders,
      syncSessionList,
      selectSyncSessionRow,
      refreshClick,
      toLocaleDateTimeString
    }
  }
}
</script>

<style lang="scss" scoped></style>
