import { render, staticRenderFns } from "./TreatmentTypeEditor.vue?vue&type=template&id=96a37fb4&scoped=true"
import script from "./TreatmentTypeEditor.vue?vue&type=script&lang=js"
export * from "./TreatmentTypeEditor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96a37fb4",
  null
  
)

export default component.exports