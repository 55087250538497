<template>
  <div>
    <div v-if="settings" class="d-flex">
      <div>
        <v-card class="mt-3" width="400">
          <v-card-title class="subtitle-1 blue--text">
            Settings
          </v-card-title>
          <v-card-text>
            <TranCodePicker
              v-model="settings.PaymentTranCodeId"
              label="Payment Transaction Type"
            />
            <v-tooltip top v-if="tranCodeGroupSelectList">
              <template v-slot:activator="{ on }">
                <v-select
                  v-on="on"
                  v-model="settings.DebitTypeToSend"
                  label="Default Income TranCode Group"
                  :items="tranCodeGroupSelectList"
                  item-text="Text"
                  item-value="Value"
                  dense
                />
              </template>
              <v-card width="450">
                <v-card-text>
                  When sending an Income transaction code to Quickbooks
                  (Quickbooks calls this an 'item'), and that transaction code
                  uses a trancode group that does not exist as an account in
                  Quickbooks, the transaction code will be sent to QuickBooks
                  using this trancode group instead.
                </v-card-text>
              </v-card>
            </v-tooltip>
            <v-tooltip top v-if="tranCodeGroupSelectList">
              <template v-slot:activator="{ on }">
                <v-select
                  v-on="on"
                  v-model="settings.CreditTypeToSend"
                  label="Default Credit TranCode Group"
                  :items="tranCodeGroupSelectList"
                  item-text="Text"
                  item-value="Value"
                  dense
                />
              </template>
              <v-card width="450">
                <v-card-text>
                  When sending a credit transaction code to Quickbooks
                  (Quickbooks calls this an 'item'), and that transaction code
                  uses a trancode group that does not exist as an account in
                  Quickbooks, the transaction code will be sent to QuickBooks
                  using this trancode group instead.
                </v-card-text>
              </v-card>
            </v-tooltip>
            <DateTimePicker
              v-model="settings.LastSyncDateTime"
              label="Last Sync Date/Time"
              dense
            />
          </v-card-text>
          <v-card-actions>
            <v-btn color="success" @click="updateClick">
              <span>Update</span>
              <v-icon right>{{ icon.save }}</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <div>
        <v-card class="mt-3 ml-3" width="400">
          <v-card-title class="subtitle-1 blue--text">
            Customer Types
          </v-card-title>
          <v-card-text>
            <div class="subtitle-2 blue--text">
              Types to retrieve from QuickBooks
            </div>
            <v-checkbox
              v-for="customerType in settings.CustomerTypeToRetrieveList"
              v-bind:key="customerType.Value"
              v-model="customerType.Selected"
              :label="customerType.Text"
              dense
            />
            <v-select
              v-model="settings.CustomerTypeToSend"
              label="Send Customers to Quickbooks as Type"
              :items="settings.CustomerTypeToSendList"
              item-text="Text"
              item-value="Value"
            />
          </v-card-text>
        </v-card>
      </div>
    </div>

    <DialogUtil ref="dialogUtil" />
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { host } from '@/services/HostAPI'
import { icon } from '@/use/Constants'

export default {
  name: 'SyncWithSettingsEditor',
  props: {
    infoId: {
      type: String,
      default: null
    },
    isDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const dialogUtil = ref()

    const isEditMode = ref(false)
    const settings = ref()
    const tranCodeGroupSelectList = ref()

    watch(
      () => props.isDialogOpen,
      newVal => {
        newVal && initDialog()
      }
    )

    const initDialog = async () => {
      isEditMode.value = props.infoId != null
      await getQuickBooksSettings()
      await getTranCodeGroupSelectList()
    }

    const getQuickBooksSettings = async () => {
      const rq = { InfoId: props.infoId }
      const rs = await host.quickBooksSettings.retrieve(rq)
      settings.value = rs.Info
    }

    const getTranCodeGroupSelectList = async () => {
      const rq = { Criteria: props.infoId }
      const rs = await host.selectList.syncWithTranCodeGroups(rq)
      tranCodeGroupSelectList.value = rs.Info
    }

    const updateClick = async () => {
      const response = await updateQuickBooksSettings(settings.value)
      if (response.IsSuccess) {
        dialogUtil.value.inform({
          text: response.Message,
          snackbarTimeout: 10000,
          color: 'success'
        })
        context.emit('close')
      } else {
        dialogUtil.value.error(response.Message)
      }
    }

    const updateQuickBooksSettings = async info => {
      const rq = { Info: info }
      const rs = await host.quickBooksSettings.update(rq)
      return rs
    }

    initDialog()

    return {
      icon,
      dialogUtil,
      settings,
      tranCodeGroupSelectList,
      updateClick
    }
  }
}
</script>

<style lang="scss" scoped></style>
